import firebase from 'firebase'

// Initialize Firebase
const config = {
  apiKey: "AIzaSyB9wafIxGtHeGBd_oEUh0tCo0BpXigztoo",
  authDomain: "accountable-dev-environment.firebaseapp.com",
  databaseURL: "https://accountable-dev-environment.firebaseio.com",
  projectId: "accountable-dev-environment",
  storageBucket: "accountable-dev-environment.appspot.com",
  messagingSenderId: "309342979298",
  appId: "1:309342979298:web:ed1ada66c189563a"
};


/* NOTE(kleyow): Storing prod and nick/sher demo config here for quick swapping.
apiKey: "AIzaSyBJanjSvvnlUNNrodw4C3qnm6p41hrb6v4",
authDomain: "tax-plat-nick-sher-trial.firebaseapp.com",
databaseURL: "https://tax-plat-nick-sher-trial.firebaseio.com",
projectId: "tax-plat-nick-sher-trial",
storageBucket: "tax-plat-nick-sher-trial.appspot.com",
messagingSenderId: "527054963725",
appId: "1:527054963725:web:67fb8542fe469afd"

apiKey: "AIzaSyAj2Sv8SiFtTn9z2sj8s3XV8nEwVeA5i2o",
authDomain: "accountable-prod-environment.firebaseapp.com",
databaseURL: "https://accountable-prod-environment.firebaseio.com",
projectId: "accountable-prod-environment",
storageBucket: "accountable-prod-environment.appspot.com",
messagingSenderId: "388871653028",
appId: "1:388871653028:web:601b0e10dc374f25f08831"
*/

firebase.initializeApp(config);
const auth = firebase.auth();
const firestore = firebase.firestore();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const microsoftAuthProvider = new firebase.auth.OAuthProvider('microsoft.com');

firestore.settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});
firestore.enablePersistence()
  .catch(function(err) {
      if (err.code == 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code == 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
  });

const database = firebase.database();
export {
  auth,
  database,
  firebase,
  firestore,
  googleAuthProvider,
  microsoftAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
